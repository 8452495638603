import React from "react"
import s from "./listCard.module.scss"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "@components/common/LangLink"

const ListCard: React.FC<{
  data: any
}> = ({ data }) => {
  return (
    <div className={s.mainCard}>
      <Link className={s.link} to={`/community/${data.slug.current}`}>
        <Img
          fluid={data.image.asset?.fluid}
          className={s.mainImage}
          objectFit="contain"
        />
        <div dir="auto" className={s.cardInfoContainer}>
          <p className={s.tag}>{data.tags.split("/")[0]}</p>
          <p className={s.title}>{data.title}</p>
          <p className={s.date}>{data.date}</p>
        </div>
      </Link>
    </div>
  )
}

export default ListCard
