import React, { FC, useState } from "react"
import s from "./CommunitySection.module.scss"
import AllTopics from "@components/communityPage/communityMain"
import SearchCom from "@components/common/search"
import Newsread from "../../../images/newsread.svg"
import Newspaper from "../../../images/newspaper.svg"
import Podcast from "../../../images/podcast.svg"
import Layout from "../../../images/layout.svg"
import formatMessage from "@utils/texts"
import LangContext from "@contexts/lang"

//Components
type Props = {
  data: any
}

const CommunitySection: FC<Props> = ({ data }) => {
  const { lang } = React.useContext(LangContext)

  const [topic, setTopic] = useState("all")
  const [searchValue, setsearchValue] = useState("")
  const [searchResultList, setSearchResultList] = useState([])
  const [blogArticles, setBlogArticles] = useState(data)

  const blogSearch = searchTerm => {
    setsearchValue(searchTerm)
    setTopic("all")
    let intialArticles = data?.map(articles => {
      return articles?.collectionArticles[0]
    })

    let searchResult = intialArticles?.filter(
      article =>
        article?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article?.tags?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setSearchResultList(searchResult)
    setBlogArticles(searchResult.length === 0 ? intialArticles : searchResult)
  }

  const topicArticlesFilter = topic => {
    setsearchValue("")
    setTopic(topic)
    let filteredArticles = data.map(articles => {
      return articles?.collectionArticles[0]
    })

    setBlogArticles(
      filteredArticles.filter(article => article?.mainTopic?.includes(topic))
    )
  }

  return (
    <>
      <div className={s.container}>
        <section className={s.communityTopBarSection}>
          <div className={s.communityTopBarContainer}>
            <div className={s.communityTopBarNav}>
              <div className={s.communitySectionContainer}>
                <div
                  className={`${s.communitySection} ${topic === "all" &&
                    s.underline}`}
                  onClick={() => topicArticlesFilter("all")}
                >
                  <div
                    className={s.communitySectionIcon}
                    style={{
                      backgroundColor: "#ffab00",
                    }}
                  >
                    <Layout className={s.icon} />
                  </div>
                  <p className={s.communitySectionTitle}>
                    {formatMessage({ id: "allTopics", lang })}
                  </p>
                </div>
                <div
                  onClick={() => topicArticlesFilter("News")}
                  className={`${s.communitySection} ${topic === "News" &&
                    s.underline}`}
                >
                  <div
                    className={s.communitySectionIcon}
                    style={{
                      backgroundColor: "#d9f5f9",
                    }}
                  >
                    <Newspaper className={s.icon} />
                  </div>
                  <p className={s.communitySectionTitle}>
                    {formatMessage({ id: "zydaNews", lang })}
                  </p>
                </div>
                <div
                  onClick={() => topicArticlesFilter("Broadcasts")}
                  className={`${s.communitySection} ${topic === "Broadcasts" &&
                    s.underline}`}
                >
                  <div
                    className={s.communitySectionIcon}
                    style={{
                      backgroundColor: "#e8deff",
                    }}
                  >
                    <Podcast className={s.icon} />
                  </div>
                  <p className={s.communitySectionTitle}>
                    {formatMessage({ id: "broadcasts", lang })}
                  </p>
                </div>
                <div
                  onClick={() => topicArticlesFilter("Client")}
                  className={`${s.communitySection} ${topic === "Client" &&
                    s.underline}`}
                >
                  <div
                    className={s.communitySectionIcon}
                    style={{
                      backgroundColor: "#dfebff",
                    }}
                  >
                    <Newsread className={s.icon} />
                  </div>
                  <p className={s.communitySectionTitle}>
                    {formatMessage({ id: "clientStories", lang })}
                  </p>
                </div>
              </div>
            </div>
            <hr className={s.communityNavBarEndLine} />
            <div className={s.communityTopBarSearch}>
              <SearchCom
                searchBlog={searchTerm => blogSearch(searchTerm)}
                searchValue={searchValue}
              />
            </div>
          </div>
        </section>
        <hr className={s.communityTopBarEndLine} />
      </div>
      {searchValue === "" ? (
        <AllTopics
          searchBlog={searchTerm => blogSearch(searchTerm)}
          data={topic === "all" ? data : blogArticles}
          landingPage={topic === "all" ? true : false}
          search={false}
          searchTerm={""}
          noSearchResult={false}
          hotTagData={data}
        />
      ) : (
        <AllTopics
          searchBlog={searchTerm => blogSearch(searchTerm)}
          data={blogArticles}
          landingPage={false}
          search={true}
          searchTerm={searchValue}
          noSearchResult={searchResultList.length !== 0 ? false : true}
          hotTagData={data}
        />
      )}
    </>
  )
}

export default CommunitySection
