import React from "react"
import LangContext from "@contexts/lang"
import s from "./sideCard.module.scss"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "@components/common/LangLink"
const SideCard: React.FC<{
  data: any
  first: boolean
}> = ({ data, first }) => {
  const { lang } = React.useContext(LangContext)

  return (
    <Link className={s.link} to={`/community/${data.slug.current}`}>
      <div
        className={s.sideCard}
        style={{
          marginTop: first ? "" : "24px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Img
          fluid={data.image.asset?.fluid}
          className={s.mainImage}
          objectFit="contain"
        />
        <div dir="auto" className={s.detailsContainer}>
          <p className={s.tag}>{data.tags.split("/")[0]}</p>
          <p className={s.title}>{data.title}</p>
          <p className={s.date}>{data.date}</p>
        </div>
      </div>
    </Link>
  )
}

export default SideCard
