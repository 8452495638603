import React, { FC } from "react"
import { graphql } from "gatsby"
import { SanityPage, sanity_typename } from "@utils/types"
import LangContext from "@contexts/lang"
import SEO from "@components/common/seo/seo"
import CommunitySection from "@components/communityPage/communitySection"

type Props = {
  data: { community: SanityPage }
  location: string
}

const CommunityPage: FC<Props> = ({ data: { community }, location }) => {
  const { lang } = React.useContext(LangContext)
  const sanityArticle = community.content.filter(
    c => c.__typename === sanity_typename.SanityPostRichSection
  )

  return (
    <>
      <SEO title={community.title[lang]} location={location} />
      <CommunitySection data={sanityArticle} />
    </>
  )
}

export default CommunityPage

export const query = graphql`
  {
    community: sanityPage(slug: { current: { eq: "/community" } }) {
      title {
        en
        ar
      }
      content {
        ...SanityPostRichSection
      }
    }
  }
`
