import React, { FC, useState } from "react"
import s from "./AllTopics.module.scss"
import LangContext from "@contexts/lang"
import SideCard from "@components/common/sideCard/sideCard"
import MainCard from "@components/common/mainCard"
import ListCard from "@components/common/listCard"
import formatMessage from "@utils/texts"

//Components

const AllTopics: FC<{
  data: any
  hotTagData: any
  searchBlog: any
  landingPage: boolean
  search: boolean
  searchTerm: string
  noSearchResult: boolean
}> = ({
  data,
  hotTagData,
  searchBlog = () => {},
  landingPage = true,
  search = false,
  searchTerm = "",
  noSearchResult = false,
}) => {
  const { lang } = React.useContext(LangContext)
  const [articlesCount, setArticlesCount] = useState(6)

  const staffPicks = (arr, main = false) => {
    let articles = arr.map(articles => {
      return articles.collectionArticles
        ? articles.collectionArticles[0]
        : articles
    })
    return main
      ? articles?.filter(
          article =>
            article?.mainTopic?.includes("staff") &&
            article?.mainTopic?.includes("main")
        )
      : articles?.filter(
          article =>
            article?.mainTopic?.includes("staff") &&
            article?.mainTopic?.indexOf("main") === -1
        )
  }
  let lattest = [...data]
    .reverse()
    .map(lattestArticles =>
      lattestArticles?.collectionArticles
        ? lattestArticles?.collectionArticles[0]
        : lattestArticles
    )

  const extendArticlesCount = () => {
    setArticlesCount(articlesCount + 6)
  }

  let highestTag = arrayOfTags => {
    let counts = {}

    for (let i = 0, len = arrayOfTags.length; i < len; i++) {
      let word = arrayOfTags[i]

      if (counts[word] === undefined) {
        counts[word] = 1
      } else {
        counts[word] = counts[word] + 1
      }
    }

    const sortable = Object.entries(counts)
      .sort(([, a], [, b]) => a - b)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

    return Object.keys(sortable).reverse()
  }

  let hotTags = () => {
    let allArticlesTags = []
    let tagsStringArray = hotTagData.map(a => a?.collectionArticles[0]?.tags)
    let tagsArrayOfArrays = tagsStringArray?.map(tagString => tagString.split("/"))
      .map(articleTagArray => articleTagArray?.map(tag => tag.trim()))
    tagsArrayOfArrays?.map(singleArticleTagArray => {
      return (allArticlesTags = [...allArticlesTags, ...singleArticleTagArray])
    })
    return highestTag(allArticlesTags)
  }

  return (
    <div className={s.allTopicsContainer}>
      {!!landingPage && (
        <section className={s.staffPicks}>
          <div className={s.staffPicksTitleWrapper}>
            <p className={s.staffPicksTitle}>
              {" "}
              {formatMessage({
                id: "staffPicks",
                lang,
              })}
            </p>
          </div>
          <div className={s.cardsWrapper}>
            <MainCard data={staffPicks(data, true)[0]} />
            <div className={s.sideCardsWrapper}>
              {staffPicks(data, false).map(article => (
                <div key={article.title}>
                  <SideCard
                    data={article}
                    first={article.title === staffPicks(data, false)[0].title}
                  />
                  {staffPicks(data, false)[staffPicks(data, false).length - 1]
                    .title !== article.title && <hr className={s.endLine} />}
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <section className={s.hotTagsSection}>
        <p className={s.hotTagsTitle}>
          {formatMessage({
            id: "hotTags",
            lang,
          })}
        </p>
        <ul className={s.tagsList}>
          {hotTags()
            .slice(0, 7)
            .map(tag => (
              <>
                <li className={s.tagsListItem} onClick={() => searchBlog(tag)}>
                  #{tag}
                </li>
              </>
            ))}
        </ul>
      </section>
      {!noSearchResult ? (
        <section className={s.articlesList}>
          <p className={s.articlesListTitle}>
            {search
              ? `Search Results for "${searchTerm}"`
              : formatMessage({
                  id: "latestArticles",
                  lang,
                })}
          </p>
          <div className={s.articlesListWrapper}>
            {lattest.slice(0, articlesCount).map(article => (
              <ListCard data={article} />
            ))}
          </div>
          {lattest.length - articlesCount > 0 && (
            <div className={s.loadMoreWrapper}>
              <button
                className={s.loadMoreWrapperButton}
                type="button"
                onClick={extendArticlesCount}
              >
                {" "}
                {formatMessage({
                  id: "loadMore",
                  lang,
                })}
              </button>
            </div>
          )}
        </section>
      ) : (
        <div style={{ margin: "20px 0" }}>
          <p>
            {formatMessage({
              id: "noSeachResultOne",
              lang,
            })}{" "}
            "{searchTerm}"
            {formatMessage({
              id: "noSeachResultTwo",
              lang,
            })}{" "}
            marketing@zyda.com
          </p>
        </div>
      )}
    </div>
  )
}

export default AllTopics
