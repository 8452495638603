import React from "react"
import LangContext from "@contexts/lang"
import s from "./searchCom.module.scss"
import SearchIcon from "../../../images/iconsearch.svg"
const SearchCom: React.FC<{
  searchBlog: any
  searchValue: string
}> = ({ searchBlog, searchValue }) => {
  const { lang } = React.useContext(LangContext)

  return (
    <div className={s.searchContainer}>
      <SearchIcon className={s.searchIcon} />
      <input
        type="search"
        placeholder="Search here"
        className={s.searchInput}
        value={searchValue}
        onChange={e => searchBlog(e.target.value)}
      />
    </div>
  )
}

export default SearchCom
